import Document from "../components/v2/Document";
import Section from "../components/section/Section";
import Title from "../components/Title";

const TermsAndCondition = () => (
	<Document
		title="Terms & Conditions"
		className="page-terms"
		ogData={{ url: "/terms-and-conditions" }}
		accentureAccessible={true}
	>
		<Section>
			<div className="row">
				<div className="col">
					<Section className="py-8 py-md-20" smallContainer={true}>
						<Title>Terms & Conditions</Title>
						<p>Please read all these terms and conditions.</p>
						<p>
							As we can accept your order and make a legally enforceable agreement without further reference to you, you
							must read these terms and conditions to make sure that they contain all that you want and nothing that you
							are not happy with.
						</p>
						<h4 id="application">Application</h4>
						<ol>
							<li>
								These Terms and Conditions will apply to the purchase of the services by you (the Customer or you). We
								are Stay Nimble Ltd a company registered in England and Wales under number 11129024 whose registered
								office is at 20-22 Wenlock Road, London, N1 7GU and whose trading address is 14 Wellington St,
								Littleport CB6 1PN with email address{" "}
								<a className="link" href="mailto:info@staynimble.co.uk">
									info@staynimble.co.uk
								</a>
								; (the Supplier or us or we).
							</li>
							<li>
								These are the terms on which we sell all Services to you. Before placing an order on the Website, you
								will be asked to agree to these Terms and Conditions by clicking on the button marked ‘I Accept’. If you
								do not click on the button, you will not be able to complete your Order. You can only purchase the
								Services from the Website if you are eligible to enter into a contract and are at least 13 years old.
							</li>
						</ol>
						<h4 id="interpretation">Interpretation</h4>
						<ol start="3">
							<li>
								Consumer means an individual acting for purposes which are wholly or mainly outside his or her trade,
								business, craft or profession;
							</li>
							<li>Contract means the legally-binding agreement between you and us for the supply of the Services;</li>
							<li>
								Delivery Location means the Supplier’s premises or other location where the Services are to be supplied,
								as set out in the Order;
							</li>
							<li>
								Durable Medium means paper or email, or any other medium that allows information to be addressed
								personally to the recipient, enables the recipient to store the information in a way accessible for
								future reference for a period that is long enough for the purposes of the information, and allows the
								unchanged reproduction of the information stored;
							</li>
							<li>
								Order means the Customer’s order for the Services from the Supplier as submitted following the step by
								step process set out on the Website;
							</li>
							<li>
								Privacy Policy means the terms which set out how we will deal with confidential and personal information
								received from you via the Website;
							</li>
							<li>
								Services means the services advertised on the Website of the number and description set out in the
								Order;
							</li>
							<li>
								Website means our website{" "}
								<a className="link" href="http://www.staynimble.co.uk">
									www.staynimble.co.uk
								</a>{" "}
								on which the Services are advertised.
							</li>
						</ol>
						<h4 id="services">Services</h4>
						<ol start="11">
							<li>
								The description of the Services is as set out in the Website, catalogues, brochures or other form of
								advertisement. Any description is for illustrative purposes only.
							</li>
							<li>All Services which appear on the Website are subject to availability.</li>
							<li>
								We can make changes to the Services which are necessary to comply with any applicable law or safety
								requirement. We will notify you of these changes.
							</li>
						</ol>
						<h4 id="customer-responsibilities">Customer responsibilities</h4>
						<ol start="14">
							<li>
								You must co-operate with us in all matters relating to the Services and provide us with all information
								required to perform the Services.
							</li>
							<li>
								Failure to comply with the above is a Customer default which entitles us to suspend performance of the
								Services until you remedy it or if you fail to remedy it following our request, we can terminate the
								Contract with immediate effect on written notice to you.
							</li>
						</ol>
						<h4 id="personal-information-and-registration">Personal information and Registration</h4>
						<ol start="16">
							<li>
								When registering to use the Website you must set up a username and password. You remain responsible for
								all actions taken under the chosen username and password and undertake not to disclose your username and
								password to anyone else and keep them secret.
							</li>
							<li>We retain and use all information strictly under the Privacy Policy.</li>
							<li>
								We may contact you by using e-mail or other electronic communication methods and you expressly agree to
								this.
							</li>
						</ol>
						<h4 id="basis-of-sale">Basis of Sale</h4>
						<ol start="19">
							<li>
								The description of the Services in our website does not constitute a contractual offer to sell the
								Services. When an Order has been submitted on the Website, we can reject it for any reason, although we
								will try to tell you the reason without delay.
							</li>
							<li>
								The Order process is set out on the Website. Each step allows you to check and amend any errors before
								submitting the Order. It is your responsibility to check that you have used the ordering process
								correctly.
							</li>
							<li>
								A Contract will be formed for the Services ordered only when you receive an email from us confirming the
								Order (Order Confirmation). You must ensure that the Order Confirmation is complete and accurate and
								inform us immediately of any errors. We are not responsible for any inaccuracies in the Order placed by
								you. By placing an Order you agree to us giving you confirmation of the Contract by means of an email
								with all information in it (ie the Order Confirmation). You will receive the Order Confirmation within a
								reasonable time after making the Contract, and before performance begins of any of the Services.
							</li>
							<li>
								No variation of the Contract, whether about description of the Services, Fees or otherwise, can be made
								after it has been entered into unless the variation is agreed by the Customer and the Supplier in
								writing.
							</li>
							<li>
								We intend that these Terms and Conditions apply only to a Contract entered into by you as a Consumer. If
								this is not the case, you must tell us, so that we can provide you with a different contract with terms
								which are more appropriate for you and which might, in some respects, be better for you, eg by giving
								you rights as a business.
							</li>
						</ol>
						<h4 id="fees-and-payment">Fees and Payment</h4>
						<ol start="24">
							<li>
								The fees (Fees) for the Services or other charges is that set out on the Website at the date we accept
								the Order or such other price as we may agree in writing. Prices for Services may be calculated on a
								fixed price or on a standard daily rate basis.
							</li>
							<li>Fees and charges include VAT at the rate applicable at the time of the Order.</li>
							<li>
								You must pay by submitting your credit or debit card or direct debit details with your Order and we can
								take payment immediately or otherwise before delivery of the Services.
							</li>
						</ol>
						<h4 id="delivery">Delivery</h4>
						<ol start="27">
							<li>
								We will deliver the Services within the agreed period or, failing any agreement, in the case of
								Services, within a reasonable time.
							</li>
							<li>
								In any case, regardless of events beyond our control, if we do not deliver the Services on time, you can
								require us to reduce the Fees or charges by an appropriate amount (including the right to receive a
								refund for anything already paid above the reduced amount). The amount of the reduction can, where
								appropriate, be up to the full amount of the Fees or charges.
							</li>
							<li>
								If you treat the Contract at an end, we will (in addition to other remedies) promptly return all
								payments made under the Contract.
							</li>
							<li>
								We do not generally provide services to addresses outside England and Wales, Scotland, Northern Ireland,
								the Isle of Man and Channels Islands. If, however, we accept an Order for delivery outside that area,
								you may need to pay import duties or other taxes, as we will not pay them.
							</li>
						</ol>
						<h4 id="withdrawal-returns-and-cancellation">Withdrawal returns and cancellation</h4>
						<ol start="31">
							<li>
								You can withdraw the Order by telling us before the Contract is made, if you simply wish to change your
								mind and without giving us a reason, and without incurring any liability.
							</li>
							<li>
								You can cancel the Contract by telling us no later than 14 days after the Contract was made, if you
								simply wish to change your mind and without giving us a reason, and without liability. Then we must
								without delay refund to you the price for those Services which have been paid for in advance. This does
								not affect your rights when the reason for the cancellation is any defective Services.
							</li>
							<li>
								This is a distance contract which has the cancellation rights (Cancellation Rights) set out below.
							</li>
						</ol>
						<h5 id="right-to-cancel">Right to cancel</h5>
						<ol start="34">
							<li>
								Subject as stated in these Terms and Conditions, you can cancel this contract within 14 days without
								giving any reason.
							</li>
							<li>The cancellation period will expire 14 days from the day the Contract was entered into.</li>
							<li>
								To exercise the right to cancel, you must inform us of your decision to cancel this Contract by a clear
								statement setting out your decision (eg email or submitting the cancellation form or any other clear
								statement of the Customer’s decision to cancel the Contract on our website{" "}
								<a className="link" href="http://www.staynimble.co.uk">
									www.staynimble.co.uk
								</a>
								. If you use this option, we will communicate to you an acknowledgement of receipt of such a
								cancellation in a Durable Medium (eg by email) without delay.
							</li>
							<li>
								To meet the cancellation deadline, it is sufficient for you to send your communication concerning your
								exercise of the right to cancel before the cancellation period has expired.
							</li>
						</ol>
						<h5 id="commencement-of-services-in-the-cancellation-period">
							Commencement of Services in the cancellation period.
						</h5>
						<ol start="38">
							<li>
								We must not begin the supply of a service (being part of the Services) before the end of the
								cancellation period unless you have made an express request for the service.
							</li>
						</ol>
						<h5 id="effects-of-cancellation-in-the-cancellation-period">
							Effects of cancellation in the cancellation period
						</h5>
						<ol start="39">
							<li>
								Except as set out below, if you cancel this Contract, we will reimburse to you all payments received
								from you.
							</li>
						</ol>
						<h5 id="payment-for-services-commenced-during-the-cancellation-period">
							Payment for Services commenced during the cancellation period.
						</h5>
						<ol start="40">
							<li>
								Where a service is supplied (being part of the Service) before the end of the cancellation period in
								response to your express request to do so, you must pay an amount for the supply of the service for the
								period for which it is supplied, ending with the time when we are informed of your decision to cancel
								the Contract. This amount is in proportion to what has been supplied in comparison with the full
								coverage of the Contract. This amount is to be calculated on the basis of the total price agreed in the
								Contract or, if the total price were to be excessive, on the basis of the market value of the service
								that has been supplied, calculated by comparing prices for equivalent services supplied by other
								traders. You will bear no cost for supply of that service, in full or in part, in this cancellation
								period if that service is not supplied in response to such a request.
							</li>
						</ol>
						<h5 id="timing-of-reimbursement">Timing of reimbursement.</h5>
						<ol start="41">
							<li>
								We will make the reimbursement using the same means of payment as you used for the initial transaction,
								unless you have expressly agreed otherwise; in any event, you will not incur any fees as a result of the
								reimbursement.
							</li>
						</ol>
						<h4 id="conformity">Conformity</h4>
						<ol start="42">
							<li>We will supply the Services with reasonable skill and care.</li>
							<li>
								In relation to the Services, anything we say or write to you, or anything someone else says or writes to
								you on our behalf, about us or about the Services, is a term of the Contract (which we must comply with)
								if you take it into account when deciding to enter this Contract, or when making any decision about the
								Services after entering into this Contract. Anything you take into account is subject to anything that
								qualified it and was said or written to you by us or on behalf of us on the same occasion, and any
								change to it that has been expressly agreed between us (before entering this Contract or later).
							</li>
						</ol>
						<h4 id="duration-termination-and-suspension">Duration, termination and suspension</h4>
						<ol start="44">
							<li>The Contract continues as long as it takes us to perform the Services.</li>
							<li>
								Either you or we may terminate the Contract or suspend the Services at any time by a written notice of
								termination or suspension to the other if that other:
								<ul>
									<li>
										commits a serious breach, or series of breaches resulting in a serious breach, of the Contract and
										the breach either cannot be fixed or is not fixed within 30 days of the written notice; or
									</li>
									<li>is subject to any step towards its bankruptcy or liquidation.</li>
								</ul>
							</li>
							<li>
								On termination of the Contract for any reason, any of our respective remaining rights and liabilities
								will not be affected.
							</li>
						</ol>
						<h4 id="circumstances-beyond-the-control-of-either-party">
							Circumstances beyond the control of either party
						</h4>
						<ol start="47">
							<li>
								In the event of any failure by a party because of something beyond its reasonable control:
								<ul>
									<li>the party will advise the other party as soon as reasonably practicable; and</li>
									<li>
										the party’s obligations will be suspended so far as is reasonable, provided that that party will act
										reasonably, and the party will not be liable for any failure which it could not reasonably avoid,
										but this will not affect the Customer’s above rights relating to delivery (and the right to cancel
										below).
									</li>
								</ul>
							</li>
						</ol>
						<h4 id="privacy">Privacy</h4>
						<ol start="48">
							<li>
								Your privacy is critical to us. We respect your privacy and comply with the General Data Protection
								Regulation with regard to your personal information.
							</li>
							<li>
								These Terms and Conditions should be read alongside, and are in addition to our policies, including our
								privacy policy (
								<a className="link" href="https://staynimble.co.uk/privacy-policy">
									https://staynimble.co.uk/privacy-policy
								</a>
								).
							</li>
							<li>
								For the purposes of these Terms and Conditions:
								<ul>
									<li>
										‘Data Protection Laws’ means any applicable law relating to the processing of Personal Data,
										including, but not limited to the Directive 95/46/EC (Data Protection Directive) or the GDPR.
									</li>
									<li>‘GDPR’ means the General Data Protection Regulation (EU) 2016/679.</li>
									<li>
										‘Data Controller’, ‘Personal Data’ and ‘Processing’ shall have the same meaning as in the GDPR.
									</li>
								</ul>
							</li>
							<li>We are a Data Controller of the Personal Data we Process in providing the Services to you.</li>
							<li>
								Where you supply Personal Data to us so we can provide Services to you, and we Process that Personal
								Data in the course of providing the Services to you, we will comply with our obligations imposed by the
								Data Protection Laws:
								<ul>
									<li>
										before or at the time of collecting Personal Data, we will identify the purposes for which
										information is being collected;
									</li>
									<li>we will only Process Personal Data for the purposes identified;</li>
									<li>we will respect your rights in relation to your Personal Data; and</li>
									<li>
										we will implement technical and organisational measures to ensure your Personal Data is secure.
									</li>
								</ul>
							</li>
							<li>
								For any enquiries or complaints regarding data privacy, you can contact our Data Protection Officer at
								the following e-mail address:{" "}
								<a className="link" href="mailto:dominic@staynimble.co.uk">
									dominic@staynimble.co.uk
								</a>
								.
							</li>
						</ol>
						<h4 id="excluding-liability">Excluding liability</h4>
						<ol start="54">
							<li>
								The Supplier does not exclude liability for: (i) any fraudulent act or omission; or (ii) death or
								personal injury caused by negligence or breach of the Supplier’s other legal obligations. Subject to
								this, we are not liable for (i) loss which was not reasonably foreseeable to both parties at the time
								when the Contract was made, or (ii) loss (eg loss of profit) to your business, trade, craft or
								profession which would not be suffered by a Consumer - because we believe you are not buying the
								Services wholly or mainly for your business, trade, craft or profession.
							</li>
						</ol>
						<h4 id="governing-law-jurisdiction-and-complaints">Governing law, jurisdiction and complaints</h4>
						<ol start="55">
							<li>The Contract (including any non-contractual matters) is governed by the law of England and Wales.</li>
							<li>
								Disputes can be submitted to the jurisdiction of the courts of England and Wales or, where the Customer
								lives in Scotland or Northern Ireland, in the courts of respectively Scotland or Northern Ireland.
							</li>
							<li>
								We try to avoid any dispute, so we deal with complaints as follows: If a dispute occurs customers should
								contact us to find a solution. We will aim to respond with an appropriate solution within 3 days.
							</li>
							<li>
								We aim to follow these codes of conduct, copies of which you can obtain as follows: Blueprint for Better
								Business available from{" "}
								<a
									className="link"
									href="https://beta.companieshouse.gov.uk/company/11129024/filing-history/QTdCRllMMlJhZGlxemtjeA/document?format=pdf&amp;download=0"
								>
									https://beta.companieshouse.gov.uk/company/11129024/filing-history/QTdCRllMMlJhZGlxemtjeA/document?format=pdf&amp;download=0
								</a>
							</li>
						</ol>
					</Section>
				</div>
			</div>
		</Section>
	</Document>
);

export default TermsAndCondition;
